/* equivalent to Extra Light */
@font-face {
  font-family: UrbanistLight;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-Light.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-Light.ttf) format("woff");
  font-weight: 100;
  font-style: normal;
}

/* equivalent to Extra Light Italic */
@font-face {
  font-family: UrbanistLight;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-LightItalic.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-LightItalic.ttf) format("woff");
  font-weight: 100;
  font-style: italic;
}

/* equivalent to Light */
@font-face {
  font-family: UrbanistRegular;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-Regular.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-Regular.ttf) format("woff");
  font-weight: 200;
  font-style: normal;
}

/* equivalent to Light Italic */
@font-face {
  font-family: UrbanistRegular;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-Italic.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-Italic.ttf) format("woff");
  font-weight: 200;
  font-style: italic;
}

/* equivalent to SemiLight */
@font-face {
  font-family: UrbanistMedium;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-Medium.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-Medium.ttf) format("woff");
  font-weight: 300;
  font-style: normal;
}

/* equivalent to SemiLight Italic */
@font-face {
  font-family: UrbanistMedium;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-MediumItalic.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-MediumItalic.ttf) format("woff");
  font-weight: 300;
  font-style: italic;
}

/* equivalent to Regular */
@font-face {
  font-family: UrbanistBold;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-Bold.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-Bold.ttf) format("woff");
  font-weight: normal;
  font-weight: 400;
  font-style: normal;
}

/* equivalent to Regular Italic*/
@font-face {
  font-family: UrbanistBold;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-BoldItalic.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-BoldItalic.ttf) format("woff");
  font-weight: normal;
  font-weight: 400;
  font-style: italic;
}

/* equivalent to Bold */
@font-face {
  font-family: UrbanistExtraBold;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-ExtraBold.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-ExtraBold.ttf) format("woff");
  font-weight: bold;
  font-weight: 700;
  font-style: normal;
}

/* equivalent to Bold Italic*/
@font-face {
  font-family: UrbanistExtraBold;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-ExtraBoldItalic.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-ExtraBoldItalic.ttf) format("woff");
  font-weight: bold;
  font-weight: 700;
  font-style: italic;
}

/* equivalent to Heavy */
@font-face {
  font-family: UrbanistBlack;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-Black.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-Black.ttf) format("woff");
  font-weight: 900;
  font-style: normal;
}

/* equivalent to Heavy Italic */
@font-face {
  font-family: UrbanistBlack;
  font-display: swap;
  src: url(./assets/fonts/urbanist/Urbanist-BlackItalic.ttf) format("embedded-opentype"),
       url(./assets/fonts/urbanist/Urbanist-BlackItalic.ttf) format("woff");
  font-weight: 900;
  font-style: italic;
}

html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
}

/* TODO : revoir le line-height pour la police responsive */

body {
  line-height: 1.48;
  background-color: #fff;
  color: #000;
  padding-top: 104px;
  overflow-x: hidden;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

b,
strong {
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
}

i {
  font-style: italic;
}

h1 {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  font-size: 2.8rem;
}

h2 {
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
}

h3 {
  font-family: UrbanistLight, Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
}

h4 {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

h5 {
  font-family: UrbanistLight, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

h6 {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
/* stylelint-disable */

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* stylelint-enable */

a,
a:hover,
a:focus,
a:active,
a:visited {
  color: inherit;
}

a:link {
  text-decoration: none;
}

.full-width {
  width: 100%;
  max-width: 100% !important;
}

.full-height,
.responsive-full-height {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.centered-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.title-home {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 6rem;
  letter-spacing: -5px;
}

.title-news {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  font-size: 3.6rem;
  letter-spacing: -3px;
}

/* Surcharge Semantic UI */

@media (max-width: 768px) {
  .responsive-full-height {
    height: initial;
  }
}

.custom-selector.ui.dropdown .menu > .item {
  font-size: inherit !important;
}

.ui.selection.dropdown {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  border: 1px solid #d3d3d3 !important;
  border-radius: 0;
  /* min-width: 300px !important; */
}

.custom-selector.ui.selection.active.dropdown,
.custom-selector.ui.selection.active.dropdown:hover,
.custom-selector.ui.selection.active.dropdown .menu,
.custom-selector.ui.selection.active.dropdown:hover .menu,
.custom-selector.ui.selection.active.dropdown .menu:hover {
  border-color: #d3d3d3;
}

.custom-selector.ui.selection.active.dropdown .menu::-webkit-scrollbar-track {
  border-radius: 10px;
}

.store-selector.ui.selection.dropdown {
  border-top: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.store-selector.ui.dropdown,
.store-selector.ui.dropdown .menu .item {
  text-transform: uppercase;
  border-color: #fff !important;
}

.footer-selector.ui.selection.active.dropdown,
.footer-selector.ui.selection.active.dropdown:hover,
.footer-selector.ui.selection.active.dropdown .menu,
.footer-selector.ui.selection.active.dropdown:hover .menu,
.footer-selector.ui.selection.active.dropdown .menu:hover {
  border-color: #fff !important;
}

.footer-selector.ui.dropdown,
.footer-selector.ui.dropdown .menu .item {
  text-transform: uppercase;
  border-color: #fff !important;
}
.custom-selector.ui.dropdown.selected,
.custom-selector.ui.dropdown .menu .selected.item {
  background-color: rgba(251, 224, 210, 0.5);
}

.custom-selector.ui.dropdown .menu > .item:hover {
  background-color: rgba(251, 224, 210, 0.95);
}

.custom-selector.ui.selection.dropdown .menu > .item {
  padding: 0.375em 1em !important;
  text-align: center;
}

.custom-selector.ui.selection.dropdown.catalog .menu > .item,
.custom-selector.ui.selection.dropdown.personal-space .menu > .item {
  padding: 0.6em 1em !important;
  text-align: left;
}

.custom-selector.ui.selection.dropdown > .dropdown.icon {
  font-size: inherit;
  padding: 0.375em;
}

.custom-selector.ui.selection.dropdown > .default.text {
  color: #d3d3d3;
}

.ui.label.filter-tag-label {
  font-size: 1.4rem;
  margin-bottom: 2px;
}

.ui.modal.custom-modal {
  width: fit-content;
}

@media (max-width: 768px) {
  .custom-selector.ui.selection.dropdown .menu {
    max-height: 20rem;
  }
  .ui.label.filter-tag-label {
    font-size: 1.4rem;
    margin-bottom: 2px;
  }
}

.footer-selector.ui.selection.dropdown > .default.text {
  color: rgba(100, 100, 100, 1);
}

.quantity-selector.ui.selection.dropdown .menu > .item {
  padding: 0.3em 0.4em !important;
  text-align: left;
}
.quantity-selector.ui.selection.dropdown > .dropdown.icon {
  font-size: 12px;
}

/* Surcharge globale pour avoir un focus plus compliant avec la charte graphique */

:focus {
  outline-color: transparent;
}

/* radio button ovveride */

.ui.radio.checkbox {
  min-height: 26px;
  line-height: 18px;
  min-width: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ui.radio.checkbox .box::before,
.ui.radio.checkbox label::before {
  width: 16px;
  height: 16px;
}
.ui.radio.checkbox input[type="checkbox"],
.ui.radio.checkbox input[type="radio"] {
  width: 16px;
  height: 16px;
}

.ui.checkbox .box,
.ui.checkbox label {
  padding-left: 2em;
  font-size: 1.4em;
  color: black;
}
.ui.radio.checkbox .box::after,
.ui.radio.checkbox label::after {
  width: 16px;
  height: 16px;
}
.ui.radio.checkbox input.hidden + label {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
}

/* Breadcrumb override */

.ui.breadcrumb {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.ui.breadcrumb .active.section {
  font-weight: normal;
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
}

.ui.breadcrumb .section {
  margin-bottom: 5px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

/* Gestion de l'impression */

@media screen {
  .elementToPrintOnly,
  .elementToPrintOnly * {
    /* ces elements sont visible uniquement en mode impression */
    display: none;
  }
}

@media print {
  #root {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .elementToPrintOnly,
  .elementToPrintOnly * {
    display: block;
  }
  .elementNotToPrint,
  .elementNotToPrint * {
    display: none !important;
  }
}

.ui.modal > .close {
  color: black;
  top: 0;
  right: 0;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

/* CSS textes */

.help {
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  .help {
    font-size: 1.6rem;
  }
}