.lightTitle {
  margin-bottom: 2rem;
}
.title {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem !important;
  text-transform: uppercase;
}
.subtitle {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem !important;
}

.lightTitle .border {
  border-bottom: 1px solid #ccc !important;
}

.lightTitle.center {
  text-align: center !important;
  margin: auto !important;
}
.lightTitle.upper {
  text-transform: uppercase !important;
}

@media (max-width: 767px) {
  .title {
    font-size: 1.6rem !important;
  }
  .subtitle {
    font-size: 1.6rem !important;
  }
}
