.contenu-container {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
}

.blockImg {
  width: 100%;
  display: block;
}

.backgroundColor {
  display: inline-block;
  height: 100%;
  width: 100%;
}
