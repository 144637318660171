.container {
    position: fixed;
    background: none repeat scroll 0 0 white;
    box-shadow: 0 0 8px rgba(36, 36, 36, 0.5);
    padding: 0;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    z-index: 999;
}

.topRow,
.botRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
}

.logo {
    height: 40px;
}

.midRow {
    padding: 0 1rem;
    margin-bottom: 3rem;
}

.underlined {
    text-decoration: underline;
}

.button {
    padding: 1rem 5rem;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
}

.onlyText {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.withBorder {
    background: none;
    border: solid 2px #ec407a;
    border-radius: 8px;
}
