.userBtn {
  height: 26px;
  width: 26px;
  border-width: 0;
  background: url("../../../../../assets/images/printemps_profile.png")
    center/contain no-repeat;
  cursor: pointer;
}

.logged {
  background: url("../../../../../assets/images/printemps_profile-fill.png")
    center/contain no-repeat;
  margin-right: 0.5rem;
}
