.btn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid grey;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: center / contain url("../../../../assets/images/up-arrow.svg");
  background-color: white;
  z-index: 10;
}
