.noResults {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  text-align: center;
}

.results {
  width: 80%;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  text-align: right;
  margin: auto;
}

.results.fullWidth {
  width: 100%;
}

.header {
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media (max-width: 767px) {
  .results {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1.2rem;
  }
}
