#breadcrumb-container {
  max-width: 1440px !important;
  padding: 2rem;
  /* height: 40px; */
  display: flex;
  align-items: center;
}

#breadcrumb-container a {
  color: #a9a9a9;
}
