.nav {
  text-align: left;
  width: 100%;
  position: relative;
}

/* CSS Mobile */

.mobileMenu {
  width: 100%;
}

.sideBar {
  z-index: 19 !important;
  background-color: #fff;
}

.sideBarContent {
  width: 100%;
  padding: 124px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 768px) {
  .sideBarContent {
    padding: 70px 10px 10px;
  }
}
@media (max-width: 320px) {
  .sideBarContent {
    padding: 50px 10px 10px;
  }
}
