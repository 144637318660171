.listContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.listContainer.centeredList {
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.5rem 0;
}

.headerTopRow {
  display: flex;
}

.headerBottomRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.displayLabel {
  margin-right: 0.5rem;
}

.displaySelection {
  border: 1px solid transparent;
  border-radius: 0;
  vertical-align: middle !important;
  padding: 0 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  background: none !important;
}

.displaySelection.active {
  color: black;
}

.displaySelection.inactive {
  color: #a9a9a9;
}

@media (max-width: 480px) {
  .header {
      display: flex;
      flex-direction: column;
  }
  .headerBottomRow {
      align-items: baseline;
  }
}