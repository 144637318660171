.mobileTopMenuItem.activeElement,
.mobileTopMenuItem.rootElement {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 2rem;
  border-bottom: solid 1px #eee;
}

.uppercase {
  text-transform: uppercase;
}

.mobileTopMenuItem {
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.mobileTopMenuItem.hidden {
  visibility: hidden;
}

.mobileMenuItem {
  width: 100% !important;
}

.prevBtn {
  position: absolute;
  left: 1rem;
  padding: 0 !important;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid transparent;
  background-color: #000000 !important;
  mask: url("../../../../../../../../assets/images/left-arrow.svg") no-repeat
    50% 50%/2.5rem 2.5rem !important;
  mask-size: contain;
}

.nextBtn {
  position: absolute;
  left: calc(100% - 5rem);
  padding: 0 !important;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid transparent;
  background-color: #000000 !important;
  mask: url("../../../../../../../../assets/images/right-arrow.svg") no-repeat
    50% 50%/2.5rem 2.5rem !important;
  mask-size: contain;
}

.secondLevel {
  display: none;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 100%;
  padding: 1rem;
}

.secondLevelTitle {
  width: 100%;
  border-bottom: solid 1px #eee;
  padding: 0.5rem 0;
}

.secondLevelItemsContainer {
  margin-top: 3rem;
  width: 100%;
}

.secondLevelItem {
  text-transform: uppercase;
  padding: 0 10rem;
  text-align: left;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.secondLevelItem .nextBtn {
  position: absolute;
  left: calc(100% - 12.6rem);
  padding: 0 !important;
  height: 1.3rem;
  width: 1.3rem;
  background-color: #000 !important;
  mask: url("../../../../../../../../assets/images/right-arrow.svg") no-repeat
    50% 50%/2.5rem 2 (rem) !important;
  mask-size: contain;
}

.secondLevel.active,
.thirdLevel.active {
  display: block;
  left: 0;
}

.thirdLevel {
  display: none;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 100%;
  padding: 1rem;
}

.thirdLevelTitle {
  width: 100%;
  border-bottom: solid 1px #eee;
  padding: 0.5rem 0;
}

.thirdLevelItemsContainer {
  margin-top: 3rem;
  width: 100%;
}

.thirdLevelItem {
  text-transform: uppercase;
  padding: 0 10rem;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.ctaContainer {
  margin-top: 16px;
  padding: 0 36px 0 36px;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

.ctaContainer a {
  font-size: 1em;
}

@media (max-width: 1025px) {
  .secondLevel,
  .thirdLevel {
    top: 104px !important;
  }

  .prevBtn {
    left: 15rem;
  }

  .nextBtn,
  .secondLevelItem .nextBtn {
    left: calc(100% - 17rem);
  }

  .secondLevelItem {
    padding: 0 30rem;
  }
}

@media (max-width: 768px) {
  .secondLevel,
  .thirdLevel {
    top: 60px !important;
  }

  .ctaContainer {
    width: auto;
  }
}

@media (max-width: 640px) {
  .secondLevelItem {
    padding: 0 10rem !important;
  }

  .secondLevel,
  .thirdLevel {
    top: 60px !important;
  }
  .prevBtn {
    left: 2rem;
  }

  .nextBtn,
  .secondLevelItem .nextBtn {
    left: calc(100% - 5rem);
  }
}

@media (max-width: 320px) {
  .secondLevel,
  .thirdLevel {
    top: 40px !important;
  }

  .prevBtn {
    left: 1rem;
  }

  .nextBtn,
  .secondLevelItem .nextBtn {
    left: calc(100% - 5rem);
  }
}
