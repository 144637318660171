.loaderContainer {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loaderContainer::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loaderContainer:not(:required) {
  /* hide "loading..." text */

  /* stylelint-disable */
  font: 0/0 a;

  /* stylelint-enable */
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  .loader,
  .loader::before,
  .loader::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: none;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .loader,
  .loader::before,
  .loader::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: none;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .loader,
  .loader::before,
  .loader::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: none;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .loader,
  .loader::before,
  .loader::after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: none;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

.loader,
.loader::before,
.loader::after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* stylelint-disable */

.loader {
  color: #000;
  font-size: 10px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

/* stylelint-enable */

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  top: 0;
}
.loader::before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader::after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
