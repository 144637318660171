.rootContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description1 {
  margin: 3rem 0;
}
.description2 {
  margin-top: 3rem;
}

.ribbonContainer {
  margin: 15px 0;
}

@media (max-width: 768px) {
  .description1 {
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  .description2 {
    margin-top: 0;
    margin-bottom: 4rem;
  }
}