.listBtn {
  height: 26px;
  width: 26px;
  border-width: 0;
  background: url("../../../../../assets/images/printemps_list.png")
    center/contain no-repeat;
  cursor: pointer;
}

.counter:global(.ui.label) {
  position: relative;
  top: -1rem;
  right: -1rem;
  background-color: #d10056 !important;
  color: white !important;
  text-align: left;
  display: flex;
  -webkit-transition: width 0.25s ease-in-out; /* For Safari 3.1 to 6.0 */
  transition: width 0.25s ease-in-out;
  width: 26px;
  height: 17px;
}

.counter span:first-child {
  text-align: center;
  width: 18px;
}

.counter span.productAddedAlert {
  text-align: right;
  flex: 1 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
  overflow: hidden;
  opacity: 0;
}

@media (min-width: 1024px) {
  .counter:global(.ui.label).expanded {
    width: 90px;
  }

  .counter:global(.ui.label).expanded span.productAddedAlert {
    opacity: 1;
  }
}
