.card {
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card.image {
  padding: 10px;
}

.card.text {
  padding: 5px 20px;
}

.cardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cardContent a:hover {
  text-decoration: underline;
}

.card.image .cardContent {
  min-width: 400px;
}

.card.text .cardContent {
  max-width: 1440px;
}

.ctaContainer {
  margin-left: 2rem;
}

.closeButton {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.hidden {
    display: none;
}

.visible .cardContent {
  animation: slide-in 0.5s;
}

@keyframes slide-in {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .card {
    flex-direction: column;
    justify-content: center;
  }

  .cardContent {
    flex-direction: column;
    justify-content: space-between;
  }

  .card.image .cardContent {
    min-width: 200px;
  }

  .ctaContainer {
    display: none;
  }
}
