.headerLogo {
  margin: 2rem auto 2.5rem;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  min-width: 200px;
}

.logoLink {
  width: 100%;
}
.headerLogo img {
  max-width: 100%;
}

.subtext {
  font-size: 11px;
}

@media screen and (prefers-reduced-motion: reduce) {
  .headerLogo {
    margin: 20px auto 25px;
    transition: none;
    max-width: 245px;
    background: none;
    border: none;
  }
}
@media (max-width: 1024px) {
  .headerLogo {
    max-height: 40px;
    margin: auto;
    min-width: unset;
  }
  .subtext {
    font-size: 9px;
    line-height: 10px;
  }
}
@media (max-width: 768px) {
  .headerLogo img {
    max-height: 38px;
  }
}
@media (max-width: 320px) {
  .headerLogo {
    height: 40px;
    margin: auto 0;
  }
  .headerLogo img {
    max-height: 38px;
  }
}
