.rootContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 75%;
}
