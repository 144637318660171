.rootContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.background {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
.subTitle {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  margin-bottom: 60px;
  line-break: 1.1;
}
.title {
  font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
  line-break: 1.3;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@media (max-width: 767px) {
  .innerWrapper {
    padding: 20px;
  }
}
