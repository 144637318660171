.separator {
  position: relative;
}
.separator .mask {
  overflow: hidden;
  height: 10px;
}
.separator .mask::after {
  content: "";
  display: block;
  margin: -15px auto 0;
  width: 100%;
  height: 15px;
  border-radius: 125px / 12px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
}
