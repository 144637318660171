.mainContainer {
  min-height: calc(100% - 220px);
  padding-bottom: 4rem;
}

.breadcrumbLanguageSelectionContainer {
  display: flex;
}

.languageSelectionContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .mainContainer {
    min-height: calc(100% - 338px);
  }
}
