.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 3px 3px rgba(36, 36, 36, 0.3);
  z-index: 20;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.bannerContainer {
  position: relative;
}

@media screen and (prefers-reduced-motion: reduce) {
  .root {
    max-width: 1440px !important;
    margin: auto;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 3px 3px rgba(36, 36, 36, 0.3);
    z-index: 10;
    -webkit-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: none;
  }
}

.headerContainer {
  padding: 0 2rem;
  height: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10;
}

#headerContainer {
  max-width: 1440px !important;
}

.headerContent {
  display: flex;
  height: 100%;
  text-align: center;
  align-items: flex-end;
  justify-content: center;
  flex-grow: 1;
}

.headerCenter {
  width: 24%;
  padding: 0 5px;
  align-items: center;
  justify-content: center;
}
.headerLeft,
.headerRight {
  width: 38%;
}

.headerRight {
  align-self: center;
  justify-content: flex-end;
}

.rightActionsContainer {
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  padding-bottom: 2rem;
  align-items: center;
}

.searchForm {
  display: flex;
  align-items: center;
}

.searchBtn {
  height: 2.5rem;
  width: 2.5rem;
  border-width: 0;
  background: url("../../../../assets/images/search.png") center/contain
    no-repeat;
}

.closeSearchBtn {
  padding: 0 !important;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #000 !important;
  mask: url("../../../../assets/images/close.svg") no-repeat 50% 50%/2rem 2rem !important;
  mask-size: contain;
  align-self: center !important;
}

.searchInput {
  min-width: 260px;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgba(169, 169, 169);
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
}

@media (max-width: 1024px) {
  .headerContent {
    width: 33%;
  }

  .headerLeft {
    line-height: inherit;
    align-self: center;
    justify-content: flex-start;
  }

  .rightActionsContainer {
    margin-right: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .searchForm {
    width: 100%;
    position: fixed;
    z-index: 19 !important;
    padding: 2rem 2.5rem;
    background-color: #fff;
    border-bottom: 2px solid #ccc;
  }

  .searchInput {
    width: 100%;
    margin: 0 1rem;
    font-size: unset;
    border-bottom: unset;
  }
}
@media (max-width: 768px) {
  .header {
    height: auto;
  }

  .headerContainer {
    padding: 0 2rem;
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .headerLeft {
    padding: 0;
    align-self: center;
  }

  .sideBarContent {
    padding: 70px 10px 10px;
  }
}
@media (max-width: 575px) {
  .headerContainer {
    margin-bottom: 11px;
  }
}
@media (max-width: 320px) {
  .headerContainer {
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .sideBarContent {
    padding: 50px 10px 10px;
  }
}

.headerRightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 2rem;
}

.menuBtn {
  padding: 0 !important;
  height: 4rem;
  width: 4rem;
  border: 1px solid transparent;
  background-color: #000 !important;
  mask: url("../../../../assets/images/menu.svg") no-repeat 50% 50%/2.5rem
    2.5rem !important;
  mask-size: contain;
  align-self: center !important;
}
.menuBtn.active {
  background-color: #000 !important;
  mask: url("../../../../assets/images/close.svg") no-repeat 50% 50%/2.5rem
    2.5rem !important;
}
