.mentions {
  font-size: 0.9rem;
}

.mentions p {
  margin-top: 0.6rem;
  text-align: justify;
}

.mentions a {
  text-decoration: underline !important;
}
