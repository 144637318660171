.classicBtn {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem;
  text-transform: uppercase;
  border: 1px solid transparent;
  background-color: black;
  color: white;
  padding: 6px 9px;
  cursor: pointer;
  border-radius: 0;
  line-height: 1.4rem;
  text-align: center;
}

.secondary {
  border: 1px solid black;
  background-color: white;
  color: black;
}

.grey {
  background-color: grey;
}

.disabled {
  border: 1px solid transparent;
  background-color: #d3d3d3;
  color: white;
  cursor: auto;
}

.themed {
  background: #f7ceb8;
  color: #fff;
}

.normalText {
  text-transform: none;
}

@media (max-width: 767px) {
  .classicBtn {
    padding: 10px 15px;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
