.container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100vh - 220px);
    width: 100%;
}

.contentContainer {
    /* height: 100%; */
    position: relative;
}

.backgroundImage {
    display: block;
    width: 100%;
    max-height: calc(100% - 220px);
}

.midLayer,
.content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.midLayer {
    background-color: rgba(0, 0, 0, 0.2);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15%;
}

.errorCode {
    font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
    font-size: 80px;
    opacity: 0.6;
    color: white;
}

.title {
    font-family: UrbanistExtraBold, Arial, Helvetica, sans-serif;
    font-size: 40px;
    color: white;
}

.subTitle {
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: white;
}

.homeBtn {
    margin-top: 30px;
}

.invisibleDiv {
    width: 100%;
    visibility: hidden;
}

@media (max-width: 1024px) {
    .content {
        justify-content: center;
        padding-top: 0;
        padding: 0 10px;
    }

    .errorCode {
        font-size: 60px;
        margin-bottom: 10px;
    }

    .title {
        font-size: 28px;
        text-align: center;
    }

    .subTitle {
        font-size: 18px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .errorCode {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 16px;
    }

    .subTitle {
        font-size: 14px;
    }
}
