.header {
    font-size: 1.4rem;
}

.msg {
    font-size: 1.4rem;
}

.positiveButton {
    font-family: UrbanistBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase;
    border: 1px solid transparent;
    background-color: #f7ceb8;
    color: black;
    padding: 6px 9px;
    cursor: pointer;
    border-radius: 0;
    line-height: 1.4rem;
    text-align: center;
}

@media (max-width: 767px) {
    .actions {
        margin-bottom: 1rem !important;
    }

    .positiveButton {
        min-width: 120px;
    }
}
