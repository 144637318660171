.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 1rem 0;
}

.filterPanel {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.closeFilterPanelBtn {
  display: flex;
  align-self: flex-end;
  margin: 0 0.25rem 1em 0 !important;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stickyDiv {
  position: sticky;
}
.openFilterPanelBtn {
  /* position: fixed; */
  left: 0;
  height: 26px;
  width: 26px;
  margin: 0.7rem 1rem !important;
  background: white;
  border: 1px solid rgba(0, 0, 0, 1) !important;
  border-radius: 13px;
  z-index: 2;
  padding: 6px 6px 6px 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
}

.filterSidebar {
  position: fixed !important;
  z-index: 9 !important;
  background: white;
  width: auto !important;
  padding: 2rem;
}

.filterContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: calc(100% / 4);
  max-width: 400px;
  overflow-y: auto;
  padding-bottom: 30px;

  /* position: fixed; */
  background-color: white;
}

.productsContainer {
  padding: 0 0.5rem;
  width: calc(100% / 3 * 2);
  display: flex;

  /* margin-left: calc(100% / 3); */
}
