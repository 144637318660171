.pageTitle {
  margin-bottom: 2rem;
}
.title {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif !important;
  text-transform: uppercase;
  font-size: 1.6rem !important;
}
.subtitle {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem !important;
}

.pageTitle .border {
  border-bottom: 1px solid #ccc !important;
}

.pageTitle.center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (max-width: 767px) {
  .subtitle {
    font-size: 1.6rem !important;
  }
}
