.footer {
  border-top: 1px solid #ddd !important;
  background-color: #fff !important;
  padding: 1em 0 !important;
  font-size: 1.4rem;
  border: none !important;
  color: rgba(100, 100, 100, 1);
}

.selector {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  border-radius: 4px !important;
  border: none !important;
  text-align: center !important;
  padding: 6px 30px 6px 16px !important;
  min-height: 0.5em !important;
  font-size: inherit !important;
  color: rgba(100, 100, 100, 1) !important;
}

.selector > .visible.menu.transition {
  border: none !important;
}

footer > .custom-selector.ui.selection.dropdown > .default.text {
  color: rgba(100, 100, 100, 1) !important;
}

.footerSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 1rem 1rem 1.5rem;
}

.footerSection.contactSection {
  align-items: center;
  font-size: 1.2rem;
}

.footerSection.legalSection {
  font-size: 1.2rem;
}

.footerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0 0.5rem;
  margin: 0 2rem;
  color: rgba(100, 100, 100, 1);
}

.newsLetterTitle {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  color: black;
}

.nlInnerForm {
  text-align: center;
}

.inputContainer {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin:auto;
}

.nlFirstRow {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.nlSecondRow {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.nlInput {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  min-width: 160px;
}

.newsLetterBtn {
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  border: 1px solid black;
  background: rgba(0, 0, 0, 1);
  font-size: 1.4rem;
  padding: 8px 9px;
  cursor: pointer;
  color: white;
  text-align: center;
  margin-left: 2px;
}

.newsLetterBtn:hover {
  border: 1px solid black;
  background: transparent;
  color: rgba(100, 100, 100, 1);
}

.socialBtn {
  height: 64px;
  width: 64px;
  background: none;
  border: 2px solid rgb(255, 255, 255);
  border-style: solid;
  margin: 1rem 0.5rem 0;
  display: inline-block;
}

.instagramBtn {
  background: center / 100% no-repeat
    url("../../../../assets/images/printemps_instagram.png");
}
.pinterestBtn {
  background: center / 100% no-repeat
    url("../../../../assets/images/printemps_pinterest.png");
}

.newsLetterBloc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsLetterForm {
  width: 115%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fbebe4;
  padding: 2rem;
}

.newsLetterHeart {
  height: 15rem;
  width: 15rem;
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}

.newsLetterLink {
  align-self: flex-start;
  margin-left: 4px;
  font-size: 1.2rem;
}
.newsLetterLink .link {
  text-decoration: underline;
}

@media (max-width: 1280px) {
  .newsLetterForm {
    width: 100%;
    align-items: flex-end;
    padding: 1rem 2rem;
  }

  .newsLetterHeart {
    height: 18rem;
    width: 18rem;
  }

  .nlInnerForm {
    width: 75%;
    display: flex;
    flex-direction: column;
  }

  .inputContainer {
    width: unset;
    grid-template-columns: 1fr;
  }

  .newsLetterTitle {
    text-align: center;
  }

  .newsLetterBtn {
    flex: 1;
  }

  .socialBtn {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 767px) {
  .newsLetterForm {
    align-items: center;
    padding: 2rem;
  }

  .nlInnerForm {
    width: 70%;
    margin-top: 5.5rem;
  }

  .nlInput {
    max-width: 100%;
    margin-top: 0;
  }

  .inputContainer {
    flex-direction: column;
    align-items: unset;
    margin: 0;
  }

  .nlFirstRow {
    grid-template-columns: 1fr;
  }
  
  .nlSecondRow {
    grid-template-columns: 1fr;
  }

  .newsLetterBtn {
    max-width: unset;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }

  .newsLetterLink {
    margin-left: 25px;
  }

  .newsLetterBloc {
    flex-direction: column;
    align-items: center;
  }

  .newsLetterHeart {
    height: 15rem;
    width: 15rem;
    top: -7.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .inputContainer :nth-child(n) + :nth-child(n) {
    margin-top: 1rem;
  }
  
  .footerSection.legalSection {
    justify-content: center;
    flex-wrap: wrap;
  }
  .footerSection.legalSection .footerColumn {
    flex: 1;
    text-align: center;
    margin: 0;
  }
}