.message {
    font-size: 1.2rem;
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
}

.tracersContainer {
    padding-left: 10px;
    padding-right: 10px;
}

.tracersContainer :global(.description) a {
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
}

.tracersContainer :global(.description) {
    margin-bottom: 10px;
}

.tracerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-family: UrbanistBold, Arial, Helvetica, sans-serif;
}

.tracerModal:global(.ui.modal) > :global(.actions) {
    padding: 1rem 1rem !important;
}

.link {
    text-decoration: underline;
}
.link:hover {
    font-weight: bold;
}

.button {
    padding: 1rem 5rem;
    font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    cursor: pointer;
}

.button + .button {
    margin-left: 0.8rem;
}

.withBorder {
    background: none;
    border: solid 2px #ec407a;
    border-radius: 8px;
}
