.ctaButton {
  font-size: 1.4rem;
  display: inline-block;
  border-radius: 0;
  padding: 6px 9px;
  line-height: 1.4rem;
  text-align: center;
}

.normal {
  border: 1px solid black;
  background: white;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.gris {
  background: grey;
}

.fullWidth {
  width: 100%;
}

.ctaButton:hover.normal,
.ctaButton:focus.normal,
.ctaButton.forceHover.normal,
.ctaButton:hover.gris,
.ctaButton:focus.gris,
.ctaButton.forceHover.gris {
  border: 1px solid black;
  background: black;
  color: white;
}

.sansBordure {
  border: 1px solid white;
  background: white;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.ctaButton:hover.sansBordure,
.ctaButton:focus.sansBordure,
.ctaButton.forceHover.sansBordure {
  border: 1px solid black;
  background: black;
  color: white;
}

.inversé {
  border: 1px solid black;
  background: black;
  color: white !important;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.ctaButton:hover.inversé,
.ctaButton:focus.inversé,
.ctaButton.forceHover.inversé {
  border: 1px solid black;
  background: white;
  color: black !important;
}

.thème {
  border: 1px solid black;
  background: white;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
}

.ctaButton:hover.thème,
.ctaButton:focus.thème,
.ctaButton.forceHover.thème {
  border: 1px solid #f7ceb8;
  background: #f7ceb8;
  color: white;
}

.thèmeInversé {
  border: 1px solid #f7ceb8;
  background: #f7ceb8;
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  color: white !important;
}

.ctaButton:hover.thèmeInversé,
.ctaButton:focus.thèmeInversé,
.ctaButton.forceHover.thèmeInversé {
  border: 1px solid black;
  background: white;
  color: black !important;
}

@media (max-width: 767px) {
  .ctaButton {
    padding: 10px 15px;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
